import React from 'react';
import { Radio } from 'antd';

export default class consts {
  //工艺类型
  static actionType = {
    // AddOil: { text: '加油', value: 1 },
    // AddWater: { text: '加水', value: 2 },
    AddDuration: { text: '加延时', value: 3 },
    AddMaterial: { text: '投料', value: 4 },
    AddSemi: { text: '半成品出菜', value: 5 },
    AddSemiClean: { text: ' 半成品出菜(并清洗)', value: 6 },
    AddSemiProduct: { text: ' 投半成品菜', value: 7 },
  };

  //生成radio列表
  static generateRadio = (mixNum, maxNum) => {
    const radioList = [];
    if (maxNum >= mixNum) {
      for (let i = mixNum; i <= maxNum; i += 1) {
        radioList.push(<Radio.Button key={i} value={i}>{i}</Radio.Button>);
      }
    }
    return radioList;
  };
  //属性类别
  static attributeType = {
    RecipeType: 0, //菜谱属性
  };
  //原/调料类别：0 = 固体，1 = 液体，2 = 辅料，3 = 酱料,4 = 溶液,100=原料
  static contentType = new Map([
    [1, '液体'],
    [0, '固体'],
    [2, '辅料'],
    [3, '酱料'],
    [4, '溶液'],
    [5, '烹饪油'],
    [6, '水'],
    [7, '芡汁'],
    [100, '原料']]);


  //料盒编号
  static containerId = {
    //1-10号料盒对应value为1-10
    autoIngredient: { text: '自动配料', value: 100 },
  };

  //菜谱状态
  static recipeStatus = {
    processing: { text: '开发中', value: 0 },
    success: { text: '上线', value: 1 },
    error: { text: '下线', value: 2 },
    default: { text: '失效', value: 3 },
  };

  //搅拌模式
  static stirMode = new Map([
    [1, '正向'],
    [2, '反向'],
    [3, '正反交替']]);

  //大料盒中原料类别
  static materialType= new Map([
    [1, '主料'],
    [2, '配料'],
  ]);

  static xa460containerId = new Map([
    [1, '1格'],
    [2, '2格'],
    [3, '3格'],
    [4, '4格'],
    [5, '辅料盒'],
  ]);

  //机器类别
  static SA2machineType = 'SA200';
  static XA4machineType = 'XA400';
  static XA460machineType = 'XA460';
  static GA480machineType = 'GA480';

  static DefaultMaxContainerLength = 3;

  //机器类别的菜谱配置
  static machineTypeRecipeConfig = {
    SA200: {
      MaxContainerLength: 10,
      Amounts: new Set([1, 2, 5, 10]),
    },
    XA400: {
      MaxContainerLength: 3,
      Amounts: new Set([1, 2, 3]),
    },
    XA460: {
      MaxContainerLength: 5,
      Amounts: new Set([1, 2, 3]),
    },
    GA480: {
      MaxContainerLength: 4,
      Amounts: new Set([1, 2, 3]),
    },
  };

  //库
  static StoreOwnerType = {
    Recipe: 1,
  };
  static StoreType = {
    None: 0, //无类型
    DevStore: 1, //开发库
    AuditStore: 2, //待审库
    MainStore: 3, //总库
  };
  static RecipeStoreStatus = new Map([
    [0, { badgeStyle: 'default', storeType: '开发库', text: '无状态', sign: 'None' }],
    [11, { badgeStyle: 'default', storeType: '开发库', text: '开发中', sign: 'Developing' }],
    [12, { badgeStyle: 'error', storeType: '开发库', text: '审核驳回', sign: 'AuditDeny' }],
    [13, { badgeStyle: 'error', storeType: '开发库', text: '总库撤回', sign: 'MainStoreRecall' }],
    [14, { badgeStyle: 'default', storeType: '开发库', text: '外部导入', sign: 'MainStoreRecall' }],
    [21, { badgeStyle: 'warning', storeType: '待审库', text: '待审核', sign: 'WaitForAudit' }],
    [31, { badgeStyle: 'success', storeType: '产品总库', text: '审核通过', sign: 'AuditPass' }],
  ]);

  static RoleNode = {
    Brand: 0, //品牌
    Branch: 1, //区域
    Shop: 2, //门店
  };

  static userRoleNode = new Map([
    [0, { text: '品牌', storeText: '品牌库', accountText: '品牌账号' }],
    [1, { text: '区域', storeText: '区域库', accountText: '区域账号' }],
    [2, { text: '门店', storeText: '门店库', accountText: '门店账号' }],
  ]);

  static brandBranchShopScope = {
    Recipe: 0,
    Material: 1,
    Ingredient: 2,
    IngredientKind: 3,
    Machine: 4,
    UserRole: 5,
  };
  
  static potChangeRequestStatus = {
    RequestApplied: 0,
    OldPotDelivered: 1,
    OldPotReceived: 2,
    StockConfirmed: 3,
    OrderPushed: 4,
    NewPotWaitForDeliver: 5,
    NewPotDelivered: 6
  }
  //售后收货地址
  static recipientAddress = '上海市浦东新区康桥东路1088号G213，上海长膳，15021375419';

}
